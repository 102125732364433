<template>
	<div class="content-wrap">
		<!--合同报表-->
		<Spin fix v-if="loading"></Spin>
		<CtmsAction>
			<Button @click="exportData">导出合同信息报表</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="data"
			:page="page"
			ref="table"
		></CtmsDataGrid>
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			:departList="departList"
			:contractId="updateId"
			:projectInfo="projectInfo"
			:entry="entry"
			:apiUrlObj="apiUrlObj"
		></Add>
	</div>
</template>

<script>
import api from "@/api/report/report"
import contractApi from "@/api/project/contract"
import Add from "@/views/Project/ContractManage/Add.vue"
import { mapState } from "vuex"

const { apiGetProjectContractPage } = api
const { apiGetDeparts, apiGetReportContractDetail } = contractApi

export default {
	name: "ContractReport",
	components: {
		Add
	},
	data() {
		return {
			loading: false,
			addVisible: false,
			entry: "",
			updateId: "",
			// 科室
			departList: [],
			// 项目基本信息
			projectInfo: {},
			apiUrlObj: {
				apiGetContractDetail: apiGetReportContractDetail
			},
			data: [],
			columns: [
				{
					title: "项目编号",
					key: "projectNo",
					minWidth: 130
				},
				{
					title: "协议类型",
					key: "type",
					minWidth: 160,
					render: (h, params) =>
						h("span", {}, params.row.type === 1 ? "主协议" : "补充协议")
				},
				{
					title: "项目类别",
					key: "projectType",
					minWidth: 140
				},
				{
					title: "项目负责科室",
					key: "department",
					minWidth: 140
				},
				{
					title: "项目负责人",
					key: "piName",
					minWidth: 140
				},
				{
					title: "手机号",
					key: "piMobile",
					minWidth: 120
				},
				{
					title: "操作",
					key: "action",
					width: 100,
					fixed: "right",
					renderButton: ({ row }) => {
						const btnList = [
							{
								label: "查看",
								on: {
									click: this.handleViewShow
								}
							}
						]
						return btnList.filter(item => !!item)
					}
				}
			],
			// 分页
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 55
		})
	},
	mounted() {
		this.getPublicData()
		this.initData()
	},
	methods: {
		getPublicData() {
			// 获取科室
			this.$asyncDo(async () => {
				const res = await apiGetDeparts()
				if (res) {
					this.departList = res.data || []
				}
			})
		},
		initData() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetProjectContractPage({
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.data = res.list || []
					this.page.total = res.total
				}
				this.loading = false
			})
		},
		// 页码改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.page.current = 1
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 查看
		handleViewShow({ row }) {
			this.updateId = row.id
			this.entry = "view"
			this.projectInfo = {}
			this.addVisible = true
		},
		exportData() {
			window.open(
				`${this.$baseUrl}/report/contract/export?token=${this.$store.state.user.token}`
			)
		}
	}
}
</script>
